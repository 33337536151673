.navbar {
  position: fixed !important;
  background-color: #ffffffe3 !important;
  box-shadow: none !important;
  color: #778186  !important;
}

.initials{
  padding: 12px;
}
.initials a, .title a {
  color: #778186  !important;
  text-decoration: none;
}

.sectionMobile {
  display: flex;
}

.quantity {
  text-align: center;
}

.price {
  text-align: right;
}

.item {
  text-align: left
}

.shoppingCartTitle {
  margin-top: 0;
  /* background-color:  #ffffffe3 !important; */
}

table {
  outline: none;
}

.quantity {
  text-align: center;
}

.price {
  text-align: right;
  padding: 5px
}

.item {
  text-align: left
}

.title {
  padding-left: 5px;
  outline: none
}

.title, .checkout {
  margin-top: 0;
}

table {
  outline: none;
  border-bottom: 1px solid black;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.btns {
  padding: 0 5px
}

.btns a {
  color: black
}

.btns:hover {
  cursor: pointer;
}

.rule {
  width: 100%;
}
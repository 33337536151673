.footer {
  padding: 0.5rem 0;
  border-top: #ebf2f6 1px solid;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.75em;
  text-align: center;
  width: 100vw;
  background-color: #ffffffe3;
}

a {
  text-decoration: none !important;
  color: #7b8d94;
}
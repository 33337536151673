body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App_App__3e7Oz {
  height: 100vh;
  overflow-x: hidden;
}
.Navbar_navbar__VuoBI {
  position: fixed !important;
  background-color: #ffffffe3 !important;
  box-shadow: none !important;
  color: #778186  !important;
}

.Navbar_initials__2gSzU{
  padding: 12px;
}
.Navbar_initials__2gSzU a, .Navbar_title__3XJ-t a {
  color: #778186  !important;
  text-decoration: none;
}

.Navbar_sectionMobile__1uHK1 {
  display: flex;
}

.Navbar_quantity__1mp-s {
  text-align: center;
}

.Navbar_price__264TO {
  text-align: right;
}

.Navbar_item__tNrNY {
  text-align: left
}

.Navbar_shoppingCartTitle__9PmvT {
  margin-top: 0;
  /* background-color:  #ffffffe3 !important; */
}

table {
  outline: none;
}

.ShoppingCart_quantity__12Hin {
  text-align: center;
}

.ShoppingCart_price__3uued {
  text-align: right;
  padding: 5px
}

.ShoppingCart_item__2sOSc {
  text-align: left
}

.ShoppingCart_title__xtLYn {
  padding-left: 5px;
  outline: none
}

.ShoppingCart_title__xtLYn, .ShoppingCart_checkout__3K9sR {
  margin-top: 0;
}

table {
  outline: none;
  border-bottom: 1px solid black;
}

.ShoppingCart_footer__3rge0 {
  display: flex;
  justify-content: space-between;
}

.ShoppingCart_btns__20LRY {
  padding: 0 5px
}

.ShoppingCart_btns__20LRY a {
  color: black
}

.ShoppingCart_btns__20LRY:hover {
  cursor: pointer;
}

.ShoppingCart_rule__1gNKT {
  width: 100%;
}
.HeroProduct_panel__1jhuB {
  height: 100vh;
  width: 100vw;
}

.HeroProduct_grid__aAMKB {
  padding-top: 150px;
}

.HeroProduct_heroHeading__1q5MI {
  font-family: 'Open Sans', sans-serif;
  color: #778186  !important;
}

.HeroProduct_heroImg__QHpLN, .HeroProduct_lostImg__1ipbZ {
  height: 50vh;
}

.HeroProduct_heroImg__QHpLN:hover {
  cursor: pointer;
}

@media only screen and (max-height: 500px) {
  .HeroProduct_grid__aAMKB {
    padding-top: 100px;
    align-items: left !important;
    flex-direction: row !important;
  }

  .HeroProduct_imgWrapper__1XxoJ {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 600px) {
  .HeroProduct_panel__1jhuB {
    height: unset !important;
  }
}
.SwipeableTextMobileStepper_wrap__p6QVo {
  width: 100%;
  height: auto;
}
.SecondPanel_panel__mjl8v {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.SecondPanel_wrap__uMuVa {
  align-self: center;
}
.ThirdPanel_panel__KgStG {
  height: 100vh;
  width: 100vw;
  background-color: green;
}
.Checkout_panel__2IeQO {
  height: 100vh;
  width: 100vw;
}
.Product_wrapper__2BQdn {
  padding: 50px 0;
  width: 100vw;
}

.Product_grid__TjA2M {
  padding-top: unset !important;  
}

.Product_imgGrid__ac5q1, .Product_unavailableProduct__9VnTs {
  text-align: center;
}

.Product_title__17Xzq {
  text-align: center;
}

.Product_unavailableProduct__9VnTs {
  margin-top: 20px !important;
}

.Product_unavailableWindow__14O_2 {
  min-height: 87.9vh;
}

/* .imgGrid, .productDetailsGrid {
  max-width: 600px !important;
  min-width: 400px !important;
} */

@media only screen and (min-width: 600px) {
  .Product_productDetails__2WDTB {
    min-height: 30vh;
  }

  .Product_picture__2AAHn {
    padding: 15px;
  }

  .Product_priceAndAdd__3ei_M {
    text-align: right;
  }
}

.Product_addToCart__3LQ5J:hover {
  cursor: pointer;
}

.Product_picture__2AAHn, .Product_lostImg__311fM, .Product_productDetails__2WDTB {
  max-width:80%;
  height: auto;
  margin: auto;
}
/* 
.MuiGrid-spacing-xs-40-139 > .MuiGrid-item-114 {
  padding: 0 !important;
} */
/* do media queries for erykah image */
.Footer_footer__3sO0w {
  padding: 0.5rem 0;
  border-top: #ebf2f6 1px solid;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.75em;
  text-align: center;
  width: 100vw;
  background-color: #ffffffe3;
}

a {
  text-decoration: none !important;
  color: #7b8d94;
}

.wrapper {
  padding: 50px 0;
  width: 100vw;
}

.grid {
  padding-top: unset !important;  
}

.imgGrid, .unavailableProduct {
  text-align: center;
}

.title {
  text-align: center;
}

.unavailableProduct {
  margin-top: 20px !important;
}

.unavailableWindow {
  min-height: 87.9vh;
}

/* .imgGrid, .productDetailsGrid {
  max-width: 600px !important;
  min-width: 400px !important;
} */

@media only screen and (min-width: 600px) {
  .productDetails {
    min-height: 30vh;
  }

  .picture {
    padding: 15px;
  }

  .priceAndAdd {
    text-align: right;
  }
}

.addToCart:hover {
  cursor: pointer;
}

.picture, .lostImg, .productDetails {
  max-width:80%;
  height: auto;
  margin: auto;
}
/* 
.MuiGrid-spacing-xs-40-139 > .MuiGrid-item-114 {
  padding: 0 !important;
} */
/* do media queries for erykah image */
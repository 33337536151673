.panel {
  height: 100vh;
  width: 100vw;
}

.grid {
  padding-top: 150px;
}

.heroHeading {
  font-family: 'Open Sans', sans-serif;
  color: #778186  !important;
}

.heroImg, .lostImg {
  height: 50vh;
}

.heroImg:hover {
  cursor: pointer;
}

@media only screen and (max-height: 500px) {
  .grid {
    padding-top: 100px;
    align-items: left !important;
    flex-direction: row !important;
  }

  .imgWrapper {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 600px) {
  .panel {
    height: unset !important;
  }
}